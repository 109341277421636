import { FC } from 'react';
import { dataAttributeProps } from '../../../utils/ComponentUtils';
import IconProps from './IconProps';

const GlobeIcon: FC<IconProps> = (props) => {
  const { onClick, className } = props;
  const cursor = onClick ? 'cursor-pointer' : '';

  return (
    <svg
      data-cy="globe-icon"
      {...dataAttributeProps(props)}
      onClick={onClick}
      className={`inline ${cursor} ${className}`}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.9333 11.594C11.76 11.0606 11.26 10.6673 10.6667 10.6673H10V8.66732C10 8.49051 9.92977 8.32094 9.80475 8.19591C9.67972 8.07089 9.51015 8.00065 9.33334 8.00065H5.33334V6.66732H6.66668C6.84349 6.66732 7.01306 6.59708 7.13808 6.47205C7.2631 6.34703 7.33334 6.17746 7.33334 6.00065V4.66732H8.66668C9.0203 4.66732 9.35944 4.52684 9.60948 4.27679C9.85953 4.02674 10 3.68761 10 3.33398V3.06065C11.9533 3.84732 13.3333 5.76065 13.3333 8.00065C13.3333 9.38732 12.8 10.6473 11.9333 11.594ZM7.33334 13.2873C4.70001 12.9607 2.66668 10.7207 2.66668 8.00065C2.66668 7.58732 2.72001 7.18732 2.80668 6.80732L6.00001 10.0007V10.6673C6.00001 11.0209 6.14049 11.3601 6.39053 11.6101C6.64058 11.8602 6.97972 12.0007 7.33334 12.0007M8.00001 1.33398C7.12453 1.33398 6.25762 1.50642 5.44879 1.84145C4.63995 2.17649 3.90502 2.66755 3.28596 3.28661C2.03572 4.53685 1.33334 6.23254 1.33334 8.00065C1.33334 9.76876 2.03572 11.4645 3.28596 12.7147C3.90502 13.3338 4.63995 13.8248 5.44879 14.1598C6.25762 14.4949 7.12453 14.6673 8.00001 14.6673C9.76812 14.6673 11.4638 13.9649 12.7141 12.7147C13.9643 11.4645 14.6667 9.76876 14.6667 8.00065C14.6667 7.12517 14.4942 6.25827 14.1592 5.44943C13.8242 4.64059 13.3331 3.90566 12.7141 3.28661C12.095 2.66755 11.3601 2.17649 10.5512 1.84145C9.7424 1.50642 8.87549 1.33398 8.00001 1.33398Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default GlobeIcon;
